import { useContext, useEffect } from "react";
import useAuth from "./useAuth";
import {privateApi} from "../api"
import {getToken} from "../utils/token"
import { AxiosError, AxiosInstance } from "axios";
import { useNavigate } from "react-router-dom"
import notify from "../utils/notify";

const useAxiosApi = () => {
    const { revokeAuth } = useAuth()
    const navigate = useNavigate();
    
    useEffect(()=> {
        const reqInterceptor = privateApi.interceptors.request.use(
            (config:any) => {
                if(!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${getToken()}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const resInterceptor = privateApi.interceptors.response.use(
            response => response,
            async (error: AxiosError) => {
                //refresh token
                if(error?.response?.status === 401){
                    revokeAuth(function(){
                        notify.error("Oops! has not authenticated")
                        navigate('/login', {replace: true});
                    })
                }
                return Promise.reject(error);
            }
        );

        return () => {
            privateApi.interceptors.request.eject(reqInterceptor)
            privateApi.interceptors.response.eject(resInterceptor)
        }
    }, [])

    return privateApi;
}

export default useAxiosApi;