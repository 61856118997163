import {createContext, ReactNode, useContext, useState} from "react"
import {removeToken, setToken} from "../utils/token"
interface IUser {
    first_name?: string,
    last_name?: string,
    country?: string,
    email?: string,
    id?: number,
    is_active?: number | boolean,
    is_verified?: number | boolean,
    profile_image?: string,
    role?: string,
    permer_link?: string,
    username?: string
}

interface IAuthContext {
    user: IUser | null,
    isAuth: boolean,
    setAuth(data?: any): void,
    revokeAuth(callback?: VoidFunction): void,
    setIsAuth(value: boolean): void,
    setUser(value: any): void
}

interface IProps {
    children: ReactNode
}


export interface IAuth {
    token?: any,
    user?: IUser | null
}

const AuthContext = createContext<IAuthContext>(null!)


export  const AuthProvider = ({children}: IProps)=> {
    const [user, setUser] = useState<IUser>({})
    const [isAuth, setIsAuth] = useState<boolean>(false);
    
    const revokeAuth = (callback: VoidFunction)=>{
        removeToken();
        setIsAuth(false);
        setUser({});
        callback()
    }

  

    const setAuth = (data?: IAuth,) => {
        setIsAuth(true);
        setToken(data?.token)
    }

    
    

    return (
        <AuthContext.Provider value={{ user, isAuth, setAuth , revokeAuth, setIsAuth, setUser}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
