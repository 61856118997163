import {notification} from 'antd';

export default {
    error(description: string): void{
        notification.error({
            message: "",
            description
        })
    },
    success(description: string): void{
        notification.success({
            message: "",
            description
        })
    },
    errors(err:any){
        const errs  = (Object.keys(err).includes("response") ? err.response.data : []);
        Object.keys(errs).forEach((f: string) => {
            notification.error({
                message: "",
                description: errs[f].toString()
            })
        })
    }

}