import { createContext, useState } from "react";



type IContentContext = {
    subjects: any[];
    subjectsMeta: any[];
    topics: {
        data: any,
        meta: any
    };
    contents: any;
    setContents(value: any): void;
    setSubjects(value: any): void;
    setTopics(value: any): void;
    revokeContent(): void,
    trigger: boolean,
    setTrigger(value: any): void,
    setSubjectsMeta(value: any): void
}

const CourseContext =  createContext<IContentContext>(null!);


export const CourseProvider = ({children}: any) => {

    const [subjects, setSubjects] = useState<any>([{name: 'samuel'}]);
    const [topics, setTopics] = useState<any>({data: [], meta: {}});
    const [contents, setContents] = useState<any>([]); 
    const [trigger, setTrigger] = useState(true);
    const [subjectsMeta, setSubjectsMeta] = useState([]);

    const revokeContent = async () => {
        setSubjects(null!);
        setContents(null!);
        setTopics(null!);
    }

    return (
        <CourseContext.Provider value={{subjects, topics, contents, setContents, setTopics, setSubjects, revokeContent, setTrigger, trigger, subjectsMeta, setSubjectsMeta}}>
            {children}
        </CourseContext.Provider>
    )
}

export default CourseContext;