import Navbar from "../components/Navbar"
import { Outlet } from "react-router-dom"


const DefaultLayout = ()=>{
    return (
    
            <Outlet />
    )
}

export default DefaultLayout