import React from 'react';
import './App.css';
import { Routes, Route} from "react-router-dom";
import Auth from './pages/Auth';
import DefaultLayout from './layouts/DefaultLayout';

//LAZY LOADED ROUTES
const Dashboard = React.lazy(()=> import("./pages/auth/Dashboard"))
const Courses = React.lazy(()=> import("./pages/auth/Courses"))
const Accounts = React.lazy(()=> import("./pages/auth/Accounts"))
const Finances = React.lazy(()=> import("./pages/auth/Finances"))
const Login = React.lazy(()=> import("./pages/Login"))
const NoMatch = React.lazy(()=>import("./pages/NoMatch"));

// ACCOUNTS
const AllUser = React.lazy(() => import('./pages/auth/accounts/AllUsers'))
const ViewUsers = React.lazy(() => import('./pages/auth/accounts/ViewUsers'))

//COURSE ROUTES
const Subjects = React.lazy(()=>import("./pages/auth/courses/Subjects"));
const Topics = React.lazy(()=>import("./pages/auth/courses/Topics"));
const Contents = React.lazy(()=>import("./pages/auth/courses/Contents"));

//CONTENT ROUTES
const AddContent  = React.lazy(() => import('./pages/auth/courses/contents/AddContent'))
const ContentList  = React.lazy(() => import('./pages/auth/courses/contents/ContentList'))


enum ROLES {
    admin,
    subadmin 
}

function App() {
    console.log(process.env.REACT_APP_PAYSTACK_SECRET_KEY)
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
            <Route path="/" element={<Auth />}>
                <Route element={<DefaultLayout />}>
                    <Route path="" element={<Dashboard/>} />
                    {/* <Route path="/courses" element={<Courses/>}> */}
                        <Route path="/courses" element={<Subjects />}/>
                        <Route path="/contents" element={<Contents />}>
                            <Route path=":topic_name" element={<ContentList />}/>
                            <Route path=":topic_name/add" element={<AddContent />}/>
                        </Route>
                        <Route path="topics/:subject_name" element={<Topics />}/>
                    {/* </Route> */}
                    <Route path="/account" element={<Accounts/>} >
                        <Route path="" element={<AllUser />}/>
                        <Route path=":username" element={<ViewUsers />}/>
                    </Route>
                    <Route path="/finance" element={<Finances/>} />
                </Route>
            </Route>
            <Route path="/login" element={<Login />} />  
            <Route path="*" element={<NoMatch />} />  
        </Routes>
    </React.Suspense>
  );
}

export default App;
