import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import {Outlet} from 'react-router-dom';
import { useLocation, Navigate } from "react-router-dom";
import { privateApi } from '../api';
import useAuth from '../hooks/useAuth';
import useAxiosApi from '../hooks/useAxiosApi';

const Auth = ()=>{

    const location =  useLocation();
    const { setIsAuth, setUser, isAuth, revokeAuth } = useAuth();
    const apiAuth = useAxiosApi()

    const [loading, setLoading] = useState(true);
    
    useEffect(()=> {
        let isMounted = true;
        const controller = new AbortController()
        
        const checkUser = async () => {
            setLoading(true)
            try {
                const response = await apiAuth.post("/account/user",{}, {signal: controller.signal});
                isMounted && setIsAuth(true)
                isMounted && setUser(response.data)
            } catch (error: any) {
                isMounted && revokeAuth()
            }finally{
                isMounted && setLoading(false)
            }
        }
        
        checkUser();

        return () => {
            isMounted = false;
            controller.abort()
        }
    }, [isAuth])

    return(
             <main className='bg-gray-100'>
                 {loading && (<div><Skeleton active={true}  /> <Skeleton  active={true} /></div>)}
                { 
                   !loading && (
                    isAuth 
                        ? <Outlet /> 
                        : <Navigate to={"/login"} state={{from: location}} replace/>  
                   ) 
                }
             </main>
        
    )
}

export default Auth