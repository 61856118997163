
    export function setToken(value: any){
        localStorage.setItem("access_token", value)
    }

    export function getToken(): string | any{
        return localStorage.getItem("access_token");
    }

    export function checkToken(): boolean{
        return !!localStorage.getItem("access_token")
    }

    export function removeToken():void {
        localStorage.removeItem("access_token");
    }