import axios from 'axios';

const baseURL: string =  `https://api.iklass.africa`;
// const baseURL: string =  `http://localhost:3333`;
const mediaURL: string =  `https://media.iklass.africa`;
// const mediaURL: string =  `http://localhost:5000`;
const paystackURL: string =  `https://api.paystack.co`;

export default axios.create({
  baseURL,
  headers: {
      'Accept': 'application/json, text/plain, */*',
  },
});



const  privateApi = axios.create({
  baseURL: `${baseURL}`,
  headers: {
      'Accept': 'application/json, multipart/formdata, text/plain, */*',
  },
  proxy: false,
  withCredentials: true
});


const  mediaApi = axios.create({
  baseURL: `${mediaURL}`,
  headers: {
      'Accept': 'application/json, multipart/formdata, text/plain, */*',
  },
  proxy: false
});

const  paystackApi = axios.create({
  baseURL: `${paystackURL}`,
  headers: {
      'Accept': 'application/json, multipart/formdata, text/plain, */*',
  },
  proxy: false
});

export {privateApi, mediaApi, paystackApi}




